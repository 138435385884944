
import BotPlatformsListCard from '@/components/bot/BotPlatformsListCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-integration-conversation',
  components: { BotPlatformsListCard },
})
export default class ProjectIntegrationConversation extends mixins(ProjectViewMixin) {}
