
import { PLATFORMS_METADATA } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'bot-platforms-list-card',
})
export default class BotPlatformsListCard extends mixins(BotMixin) {
  get platformsMetadata() {
    return PLATFORMS_METADATA;
  }
}
